<template>
  <div :class="[isMobile ? 'mobile-about-report' : '', 'about-report']">
    <!-- pdf页没有头 和其他页面一样的  -->
    <template v-if="isShow">
      <item-title
        v-if="!isMobile"
        :title="{ name: '明亚年报', en_1: 'MINGYA', en_2: 'HONORS' }"
      />

      <div class="report-card-group">
        <div class="report-card" v-for="item in yearReportData" :key="item.id" @click="goReportContent(item)">
          <img
            class="report-card-img"
            :src="item.pic"
          />
          <div class="report-card-footer">
            <div class="report-card-title">{{ item.name }}</div>
            <div class="report-card-time">
              <span>发布时间：{{ item.disploy_at && item.disploy_at.substring(0,10) }}</span>
              <span class="report-download" @click.stop="onDownload(item)">下载报告</span>
            </div>
            <div class="confirm-btn">
              立即查看<img class="arrow_white" :src="require('@/assets/img/aboutus/arrow_white.png')" />
            </div>
          </div>
        </div>
      </div>
      <pager
        :total="params.total"
        :page="params.page"
        :limit="params.limit"
        @current-change="handleCurrentChange"
      ></pager>
    </template>
  </div>
</template>
<script>
import Pager from "@/components/common/Pager.vue";
import ItemTitle from "@/components/aboutUs/itemTitle";
import { isMobile, fileLink2Download, isWXWebview } from "@/helper/utils";
import { getNewsAndDutyData, getNewsAndDutyDetail } from '@/api/aboutUs'
export default {
  components: {
    ItemTitle,
    Pager,
  },
  data() {
    return {
      isMobile: isMobile(),
      params: {
        page: 1,
        limit: 2,
        total: 20,
      },

      yearReportData: [],

      yearReportDetail: {},

      isShow: false,
      // pdfUrl: '',
    };
  },
  created() {
    this.getYearReport()
    // const testPdf =  `https://hkzs.oss-cn-beijing.aliyuncs.com/test/poster/4st6ck3j2shi4mi7kab636dm%E6%90%BA%E7%A8%8B%E5%95%86%E6%97%85%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C-%E6%98%8E%E4%BA%9A.pdf `
    // this.pdfUrl =  `./pdf/web/viewer.html?file=${testPdf}`
  },
  mounted () {
    setTimeout(() => {
      this.isShow = true
    }, 200)
  },
  methods: {
    getYearReport() {
      getNewsAndDutyData({
        limit: this.params.limit,
        page: this.params.page,
        type: 4,
      }).then(res => {
        this.params.total = res.total
        // this.params.total = 3
        this.yearReportData = res.records
        this.$emit('loading', false)
      })
    },
    goReportContent(item) {
      this.$router.push({
        name: 'reportPdf',
        query: {
          id: item.id
        }
      })
    },
    // 分页
    handleCurrentChange(val) {
      this.params.page = val;
      this.getYearReport()
      // this.$emit('loading', true)
      // this.getNewsData()
    },
    // 点击 下载
    onDownload(item){
      getNewsAndDutyDetail({ id: item.id }).then(res => {
        this.yearReportDetail = res
        if(isWXWebview()){
          window.location.href = this.yearReportDetail.detail + '?downtype=view'
        }else{
          fileLink2Download(this.yearReportDetail.detail, this.yearReportDetail.name, 'pdf')
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
:v-deep #toolbarViewer {
  height: 22px;
}

.about-report {
  margin-left: 34px;
  .report-card-group {
    display: flex;
    justify-content: space-between;
  }
  .report-card {
    width: calc(50% - 0.08rem);
    margin-top: 22px;
    border-radius: 6px;
    overflow: hidden;
    .report-card-img {
      // height: 136px;
      width: 100%;
    }
    .report-card-footer {
      // height: 45px;
      background: #f6f6f6;
      // padding: 8px 8px;
      padding: 0.15rem;
      position: relative;
      .report-card-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        // font-size: 10px;
        font-size: 0.22rem;
        color: #333333;
        // line-height: 12px;
        text-align: justify;
        font-style: normal;
      }
      .report-card-time {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        // font-size: 6px;
        font-size: 0.14rem;
        color: #333333;
        // line-height: 8px;
        text-align: justify;
        font-style: normal;
        margin-top: 0.02rem;
        display: flex;
        align-items: center;
      }
      .confirm-btn {
        cursor: pointer;
        position: absolute;
        right: 0.15rem;
        // width: 44px;
        // height: .25rem;
        top: calc(50% - 0.14rem);
        background: #fe7a1c;
        border-radius: 3px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 0.14rem;
        color: #ffffff;
        // line-height: 9px;
        text-align: justify;
        font-style: normal;
        // padding-left: 6px;
        // padding-right: 7px;
        padding: 0.08rem 0.18rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.2rem;
        .arrow_white {
          width: 0.07rem;
          height: 0.1rem;
          margin-left: 0.05rem;
        }
      }
    }
  }
  .report-download{
    font-weight:600;
    font-size: 0.14rem;
    color: #2D75E7;
    position: relative;
    padding-left: 0.18rem;
    margin-left: 0.16rem;
    cursor: pointer;
    &:before{
      content: '';
      position: absolute;
      width: 0.14rem;
      height: 0.12rem;
      background: url('~@/assets/img/aboutus/download.png');
      background-size: 100% 100%;
      left: 0;
      top: 0.05rem;
    }
  }
  .pdf_box {
    min-height: 365px;
    height: 40vw;
    width: 100%;
  }
}

.mobile-about-report {
  margin-left: 0;
  .report-card-group {
    display: block;
    .report-card {
      width: 100%;
    }
  }
  .report-card-time{
    margin-top: 0.06rem !important;
  }
}
</style>
